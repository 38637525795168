<template>
  <div style="text-align: initial">
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      filename="Prego"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="1000px"
      ref="html2Pdf"
      @progress="onProgress($event)"
    >
      <section
        slot="pdf-content"
        v-for="(item, index) in dataExport"
        :key="index"
      >
        <div class="content">
          <div class="content__label f-w6">領収書</div>
          <div
            class="content__header d-flex justify-content-between align-items-start mt-5"
          >
            <div class="content__header__name">
              <span
                style="display: inline-block; width: 480px"
                class="underline"
                >{{ item.name }}</span
              >
              様
            </div>
            <div class="d-flex flex-column" style="width: 300px">
              <div
                class="content__header__no underline d-flex justify-content-between"
              >
                <span class="text-left f-w3">No</span>
                <span class="text-right f-w3">{{
                  item.item.user_pg_point_id
                }}</span>
              </div>
              <div
                class="content__header__date underline mt-3 d-flex justify-content-between fw3"
              >
                <span class="text-left">発行日</span>
                <span class="text-right" v-if="item.status">{{
                  $dayjs(item.item.created_at).format("YYYY/MM/DD HH:mm")
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="content__point text-center"
            style="text-align: center; font-weight: bold; font-size: 30px"
          >
            金額 ¥
            {{
              Intl.NumberFormat().format(
                Math.floor(
                  item.item.money_with_points_balance / dataExport.length
                )
              )
            }}
            – (税込)
          </div>
          <span
            class="d-flex justify-content-center mt-2"
            style="font-size: 12px"
            >※割り勘の人数によって、小数点以下の端数は切り捨てた領収書になってしまうことを確認の上、ご了承ください。</span
          >
          <pre>




但書 PreGo 利用料


上記正に領収しました。

株式会社エフオーピーシー

〒141-0022 東京都品川区東五反田1-10-7 Aios五反田509</pre
          >
        </div>
        <div class="html2pdf__page-break"></div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
export default {
  components: { VueHtml2pdf },
  name: "PointPdfExport",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      usersBlocked: "auth/usersBlock",
      dataExport: "pointHistory/dataExport"
    })
  },
  methods: {
    onProgress(event) {
      this.$root.$refs.loading.start();
      if (event == 100) {
        this.$root.$refs.loading.finish();
      }
    },
    async generateReport() {
      await this.$refs.html2Pdf.generatePdf();
    },
    unBlock(user_block_id) {
      this.$root.$refs.loading.start();
      this.$store
        .dispatch("auth/unblock", { user_block_id })
        .then(() => {
          this.$root.$refs.loading.finish();
          for (let i = 0; i < this.usersBlocked.length; i++) {
            if (user_block_id === this.usersBlocked[i].user_id) {
              this.usersBlocked.splice(this.usersBlocked[i], 1);
              this.$toast("ブロックを解除する。", "通知", "success");
            }
          }
        })
        .catch(() => {
          this.$root.$refs.loading.finish();
          this.$toast("エラーが発生しました。", "通知", "danger");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_fontFamily.scss";
table,
td,
th {
  border: 1px solid black;
}

table {
  width: 100%;
  border-collapse: collapse;
}
.content {
  width: 100%;
  height: 100%;
  padding: 40px 0 0 120px;
  &__label {
    font-size: 20px;
    width: 100%;
    height: 40px;
    background-color: rgb(167, 167, 167);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .underline {
    border-bottom: 2px solid #000000;
  }
  &__point {
    margin-top: 80px;
    font-size: 30px;
    font-weight: bold;
    color: #000000;
  }
  pre {
    margin-top: 30px;
  }
}
@supports (-ms-ime-align: auto) {
  table,
  td,
  th {
    border: 1px solid black;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
  .content {
    width: 100%;
    height: 100%;
    padding: 40px 0 0 120px;
    &__label {
      font-size: 20px;
      width: 100%;
      height: 40px;
      background-color: rgb(167, 167, 167);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .underline {
      border-bottom: 2px solid #000000;
    }
    &__point {
      margin-top: 80px;
      font-size: 30px;
      font-weight: bold;
      color: #000000;
    }
    pre {
      margin-top: 30px;
    }
  }
}
</style>
